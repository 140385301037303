import format from "date-fns/format"
import {
  PDFDocument,
  StandardFonts,
  rgb,
  PageSizes
} from "pdf-lib";
import api from "./apicalls";
import { toast } from "bulma-toast"
import download from "downloadjs";
//import { hasMetadata } from "core-js/fn/reflect";

export default {

  async printTimetable(details, timetable) {


    //const dayNameWidth = 80

    let start = this.cHM(details.start)
    let end = this.cHM(details.end)



    //cHM(x) {


    const pdfDoc = await PDFDocument.create();
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    let pageSize = PageSizes["A4"]




    const page = pdfDoc.addPage([pageSize[1], pageSize[0]]);
    //page.setRotation(degrees(-90))
    const {
      width,
      height
    } = page.getSize();
    //let fontSize = 40;
    let title = details.user.LastName + ", " + details.user.FirstName


    let baseFontSize = this.calculateFontSize(40, width - 30, title, timesRomanFont)

    page.drawText(title, {
      x: 10,
      y: height - 35,
      size: baseFontSize,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });

    page.drawLine({
      start: {
        x: 10,
        y: height - 50
      },
      end: {
        x: width - 10,
        y: height - 50
      }
    })

    let heightForTimetable = height - 100

    let rowHeight = Math.min(heightForTimetable / timetable.length, 80);

    let t = timetable.map(x => x.name).map(x => this.calculateFontSize(20, 80, x, timesRomanFont))



    let baseStart = height - 50 - rowHeight

    let maxDayWidth = Math.min(...t)



    for (let day of timetable) {

      page.drawLine({
        start: {
          x: 10,
          y: baseStart
        },
        end: {
          x: width - 10,
          y: baseStart
        }
      })

      page.drawText(day.name, {
        x: 15,
        y: baseStart + (rowHeight / 2),
        size: maxDayWidth,
        font: timesRomanFont,
        color: rgb(0, 0, 0.),
      });


      for (let period of day.periods) {

        let pStart = this.convertToOffset(start, end, period.TimeStart, width - 110)
        let pEnd = this.convertToOffset(start, end, period.TimeEnd, width - 110)

        //console.log([period, pStart, pEnd])

        page.drawRectangle({
          x: pStart + 100,
          y: baseStart,
          width: pEnd - pStart,
          height: rowHeight,
          color: (period.Group && period.Group.length) ? rgb(0.75, 0.75, 0.75) : rgb(0.95, 0.95, 0.95),
          borderColor: rgb(0, 0, 0),
          borderWidth: 1
        })

        page.drawText(period.TimeStart + "-" + period.TimeEnd, {
          x: pStart + 105,
          y: baseStart + rowHeight - 10,
          size: 8,
          font: timesRomanFont,
          color: rgb(0, 0, 0.),
        });

        if (period.Subject) {
          page.drawText(period.Subject, {
            x: pStart + 105,
            y: baseStart + rowHeight - 20,
            size: 8,
            font: timesRomanFont,
            color: rgb(0, 0, 0.),
          });
        }

        if (period.RoomName && period.RoomName.length) {
          page.drawText(period.RoomName, {
            x: pStart + 105,
            y: baseStart + rowHeight - 30,
            size: 8,
            font: timesRomanFont,
            color: rgb(0, 0, 0.),
          });
        }

        if (period.Group && period.Group.length) {
          page.drawText(period.Group, {
            x: pStart + 105,
            y: baseStart + rowHeight - 40,
            size: 8,
            font: timesRomanFont,
            color: rgb(0, 0, 0.),
          });
        }




      }

      baseStart -= rowHeight

    }


    page.drawLine({
      start: {
        x: 100,
        y: height - 50
      },
      end: {
        x: 100,
        y: 50
      }
    })

    page.drawLine({
      start: {
        x: 10,
        y: height - 50
      },
      end: {
        x: 10,
        y: 50
      }
    })

    let d = new Date()

    let p = "Printed: " + format(d, "HH:mm, do MMM yyyy")

    let pw = timesRomanFont.widthOfTextAtSize(p, 8);

    page.drawText(p, {
      x: width - 11 - pw,
      y: 20,
      size: 8,
      font: timesRomanFont,
      color: rgb(0, 0, 0.),
    });




    pdfDoc.save().then((blob) => {
      download(blob, "timeTable-" + format(d, "yyyy-MM-dd-HH:mm:ss") + ".pdf", "application/pdf");
    });


  },


  calculateFontSize(startingSize, maxWidth, text, font) {

    let tmpfontSize = startingSize

    let nameWidth = font.widthOfTextAtSize(text, tmpfontSize);

    while (nameWidth > maxWidth) {
      nameWidth = font.widthOfTextAtSize(text, tmpfontSize);
      if (maxWidth < nameWidth) {
        tmpfontSize *= 0.95
      }
    }

    return tmpfontSize

  },

  convertToOffset(start, end, hm, width) {

    let minutes = this.cHM(hm)


    let x = end - start
    let y = minutes - start

    return width * (y / x)

  },

  cHM(x) {
    let q = x.split(":")



    let y = (parseInt(q[0], 10) * 60) + parseInt(q[1], 10)



    return y
  },

  async presentSession(student, first, last, session, showToast = true, timetable = null, override = false) {
    return await this.markSession(
      student,
      first,
      last,
      session,
      "/",
      1,
      (showToast) ? first + " " + last + " has been registered" : null,
      timetable, override
    )
  },

  async oaSession(student, first, last, session, showToast = true, timetable = null, override = false) {
    return await this.markSession(
      student,
      first,
      last,
      session,
      "OA",
      52,
      (showToast) ? first + " " + last + " has been marked OA" : null,
      timetable, override
    )
  },

  async unknownSession(student, first, last, session, showToast = true, timetable = null, override = false) {
    return await this.markSession(
      student,
      first,
      last,
      session,
      "N",
      46,
      (showToast) ? first + " " + last + " has unknown absence" : null,
      timetable, override
    )
  },

  async markSession(student, first, last, session, regMark, regMarkCode, toastMessage = null, timetable = null, override = false) {

    console.log([student, session, timetable, { studentID: student, isAM: session == "am", regID: regMarkCode, mode: (override ? 1 : 0) }])

    let a = await api.post("v2/staff/register/session/", true, true, { studentID: student, isAM: session == "am", regID: regMarkCode, mode: (override ? 1 : 0) })

    if (a) {
      if (toastMessage) {
        toast({
          message: toastMessage,
          type: 'is-warning',
          duration: 2000,
          closeOnClick: true,
        })
      }

      if (timetable) {
        timetable.data = timetable.data.map((x) => {
          if (x.PupilIdent != student) {
            return x
          }

          x[session] = regMark

          return x;
        })
      }
    }

    return timetable

  },

  async presentPeriod(data, showToast = true) {

    console.log(data)

    let x = await api.post("v2/staff/register/period/", true, true, { records: data })


    console.log(x)
    if (showToast) {
      toast({
        message: "Group registration has been processed",
        type: 'is-success',
        duration: 2000,
        closeOnClick: true,
      })
    }
    return x;

  },
}
