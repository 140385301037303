<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">

      <h1 class="title is-2 is-spaced">
        Missing Registrations
        <span class="is-small">{{ timeTableDay }}</span>
      </h1>



      Last generated: <time-ago :datetime="lastUpdate" tooltip refresh></time-ago>


      <div class="field is-grouped is-grouped-multiline" v-if="tagsAv.Year.length">
        <div class="control" v-for="counts in tagsAv.Year" :key="counts[0]">
          <div class="tags has-addons">
            <a :class="tagActive('Year', counts[0])" @click="toggle('Year', counts[0])">{{ counts[0]  }}</a>
            <span class="tag is-info">{{ counts[1] }}</span>
          </div>
        </div>
      </div>

      <div class="field is-grouped is-grouped-multiline" v-if="tagsAv.Class.length">
        <div class="control" v-for="counts in tagsAv.Class" :key="counts[0]">
          <div class="tags has-addons">
            <a :class="tagActive('Class', counts[0])" @click="toggle('Class', counts[0])">{{ counts[0]  }}</a>
            <span class="tag is-info">{{ counts[1] }}</span>
          </div>
        </div>
      </div>

      <div class="field is-grouped is-grouped-multiline" v-if="tagsAv.Group.length">
        <div class="control" v-for="counts in tagsAv.Group" :key="counts[0]">
          <div class="tags has-addons">
            <a :class="tagActive('Group', counts[0])" @click="toggle('Group', counts[0])">{{ counts[0]  }}</a>
            <span class="tag is-info">{{ counts[1] }}</span>
          </div>
        </div>
      </div>
      <table class="table is-bordered is-striped is-hoverable">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Pupil Name</th>
            <th :class="amClassHeader">Am</th>
            <th :class="pmClassHeader">Pm</th>
            <th>Class</th>
            <th colspan=3>Location</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in timeTableData" :key="student.PupilIdent">
            <td>{{ student.PupilFirstName }}</td>
            <td>{{ student.PupilLastName }}</td>
            <td :class="amClassHeader">{{ student.am }}</td>
            <td :class="pmClassHeader">{{ student.pm }}</td>
            <td>{{ student.Class }}</td>

            <td v-if="student.location">{{ student.location.groupName }}</td>
            <td v-if="student.location">{{ student.location.staffName }}</td>
            <td v-if="student.location">{{ student.location.roomCode }}</td>
            <td v-else colspan=3>&nbsp;</td>
            <td>
              <div class="buttons has-addons">
                <button :disabled="student[timetable.slot] == '/'"
                  @click="present(student.PupilIdent, student.PupilFirstName, student.PupilLastName, timetable.slot)"
                  class="button is-primary">Present</button>
                <button :disabled="student[timetable.slot] == 'OA'"
                  @click="oaSession(student.PupilIdent, student.PupilFirstName, student.PupilLastName, timetable.slot)"
                  class="button is-info">OA</button>
                <button :disabled="student[timetable.slot] == 'N'"
                  @click="unknownSession(student.PupilIdent, student.PupilFirstName, student.PupilLastName, timetable.slot)"
                  class="button is-warning">N</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <button class="button" v-on:click="fireRegister()">Fire Register</button>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';





import { format } from "date-fns";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import download from "downloadjs";
//import { toast } from 'bulma-toast'
import timetable from "helpers/timetable"

import { TimeAgo } from 'vue2-timeago'
import 'vue2-timeago/dist/vue2-timeago.css'



export default {
  name: "home",

  components: {
    TimeAgo
  },

  data: function () {
    return {
      students: [],
      timetable: [],
      lastUpdate: undefined,
      timer: null,
      processing: [],
      etag: null,
      tags: {
        Year: {},
        Class: {},
        Group: {}
      },
      filters: {
        Year: {},
        Class: {},
        Group: {}
      }
    };
  },
  computed: {
    timeTableData() {
      if (this.timetable.length === 0) {
        return [];
      }
      if (Object.keys(this.filters.Class).length == 0 && Object.keys(this.filters.Year).length == 0 && Object.keys(this.filters.Group).length == 0) {
        return this.timetable.data;
      }

      let x = this.timetable.data

      console.log({filter: this.filters})

      const classes = Object.entries(this.filters.Class).filter(x => x[1]).map(x => x[0])
      const years = Object.entries(this.filters.Year).filter(x => x[1]).map(x => x[0])
      const groups = Object.entries(this.filters.Group).filter(x => x[1]).map(x => x[0])

      console.log({classes, years, groups})

      if (classes.length) {
        console.log("Filtering class")
        x = x.filter(y => {
          return classes.includes(y.Class)
        })
      }

      if (years.length) {
        console.log("Filtering Year")
        x = x.filter(y => {
          console.log(y.Year, years)
          return years.includes(y.Year)
        })
      }
      if (groups.length) {
        console.log("Filtering groups")
        x = x.filter(y => {
          return groups.includes(y.Group)
        })
      }

      console.log({x, })

      return x;
    },

    tagsAv() {
      return {
        Class: Object.entries(this.tags.Class),
        Year: Object.entries(this.tags.Year),
        Group: Object.entries(this.tags.Group)
      }
    },

    amClassHeader() {
      if (this.timetable.slot == "am") {
        return "has-background-primary-light";
      }
      return undefined;
    },

    pmClassHeader() {
      if (this.timetable.slot == "pm") {
        return "has-background-primary-light";
      }
      return undefined;
    },

    timeTableDay() {
      if (this.timetable.date) {
        return this.timetable.date + " - " + this.timetable.slot;
      }

      return "";
    },

    timeTableSlot() {
      if (this.timetable.slot) {
        return this.timetable.slot;
      }

      return "";
    },

    currentDomain() {
      return this.$store.state.currentDomain;
    },
  },
  methods: {

    tagActive(index, key) {


      if (Object.keys(this.filters[index]).length == 0) {
        return 'tag  is-success'
      }

      if (this.filters[index][key]) {
        return 'tag  is-success'
      }

      for (let i of Object.entries(this.filters[index])) {

        if (i[0] == key) {
          continue
        }

        if (i[1]) {
          return 'tag  is-dark'
        }
      }

      return 'tag  is-success'


    },


    async present(student, first, last, session) {
      this.timetable = await timetable.presentSession(student, first, last, session, true, this.timetable, true);
    },

    async oaSession(student, first, last, session) {
      this.timetable = await timetable.oaSession(student, first, last, session, true, this.timetable, true);
    },

    async unknownSession(student, first, last, session) {
      this.timetable = await timetable.unknownSession(student, first, last, session, true, this.timetable, true);
    },


    toggle(index, key) {

      console.log(this.filters)


      this.$set(this.filters[index], key, !this.filters[index][key])




      console.log(this.filters)

    },



    async loadRegister() {

      let response = await this.$api.getWithEtag("v2/staff/register/missing/", true, true);


      this.timetable = response.data;
      this.lastUpdate = response.data.retrieved
      this.etag = response.etag

      let xy = this.timetable.data.reduce((p, c) => {
        p.Class[c.Class] = 1 + (p.Class[c.Class] ?? 0)
        p.Year[c.Year] = 1 + (p.Year[c.Year] ?? 0)

        if (c.location?.groupName) {
          p.Group[c.location.groupName] = 1 + (p.Group[c.location.groupName] ?? 0)
        }

        return p

      }, {
        Class: {},
        Year: {},
        Group: {}
      })

      console.log(this.tags)

      this.$set(this.tags, 'Class', xy.Class)
      this.$set(this.tags, 'Year', xy.Year)
      this.$set(this.tags, 'Group', xy.Group)

      Object.keys(this.filters.Class).forEach(x => {
        if (xy.Class[x]) {
          return
        }
        this.tags.Class[x] = false
      })

      Object.keys(this.filters.Year).forEach(x => {
        if (xy.Year[x]) {
          return
        }
        this.tags.Year[x] = false
      })

      Object.keys(this.filters.Group).forEach(x => {
        if (xy.Group[x]) {
          return
        }
        this.tags.Group[x] = false
      })


      console.log(this.tags)


      const that = this

      this.timer = setInterval(() => {
        that.$api.getWithEtag("v2/staff/register/missing/", true, true, that.etag).then((response) => {

          console.log(response.status)

          if (response.status == 200) {
            that.timetable = response.data;
            that.lastUpdate = response.data.retrieved
            that.etag = response.etag


            let xy = this.timetable.data.reduce((p, c) => {
              p.Class[c.Class] = 1 + (p.Class[c.Class] ?? 0)
              p.Year[c.Year] = 1 + (p.Year[c.Year] ?? 0)

              if (c.location?.groupName) {
                p.Group[c.location.groupName] = 1 + (p.Group[c.location.groupName] ?? 0)
              }
              return p



            }, {
              Class: {},
              Year: {},
              Group: {}
            })

            that.tags.Class = xy.Class
            that.tags.Year = xy.Year
            that.tags.Group = xy.Group

            that.filters.Class.filter(x => that.tags.Class[x])
            that.filters.Year.filter(x => that.tags.Year[x])
            that.filters.Group.filter(x => that.tags.Group[x])
          }


        });
      }, 15000);
      //console.log(this.timer)
    },
    async fireRegister() {
      let registers = await this.$api.get("v2/staff/register/fire/", true, true);

      const pdfDoc = await PDFDocument.create();
      const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
      for (let year of Object.keys(registers).sort((a, b) => {
        let aa = Number.parseInt(a) ?? 0
        let bb = Number.parseInt(b) ?? 0

        return aa - bb;

      })) {


        const page = pdfDoc.addPage();
        const { width, height } = page.getSize();
        const fontSize = 30;
        const title = registers[year].name

        const titleWidth = timesRomanFont.widthOfTextAtSize(title, fontSize);
        page.drawText(title, {
          x: width - (titleWidth + 10),
          y: height - fontSize - 10,
          size: fontSize,
          font: timesRomanFont,
          color: rgb(0, 0, 0),
        });

        page.drawText(this.$store.state.currentDate, {
          x: 10,
          y: height - fontSize - 10,
          size: fontSize,
          font: timesRomanFont,
          color: rgb(0, 0.53, 0.71),
        });

        let ystart = height - 2 * fontSize - 35;

        let lines = 22;
        //console.log(lines)

        let pm = width / 2 - 30;
        let am = pm - 35;
        let bottom = ystart - (lines - 1) * (30 + 5);

        drawVerticals(page, ystart + 22, width, bottom - 7, am, pm);
        drawHorizontals(page, ystart + 29, width);

        for (let row = 0; row < lines; row++) {
          //console.log(row)
          let rowY = ystart - (row % lines) * (30 + 5);

          //console.log([year, row, registers[year].students[row], rowY])

          drawHorizontals(page, rowY, width);



          if (registers[year].students[row]) {
            writeStudent(
              page,
              timesRomanFont,
              registers[year].students[row],
              12,
              rowY,
              am,
              pm
            );
          }

          if (registers[year].students[row + lines]) {
            writeStudent(
              page,
              timesRomanFont,
              registers[year].students[row + lines],
              12 + width / 2,
              rowY,
              am + width / 2,
              pm + width / 2
            );
          }

        }
      }



      pdfDoc.save().then((blob) => {
        download(blob, "fire-register-" + format(new Date(), "yyyy-MM dd HH:mm:ss") + ".pdf", "application/pdf");
      });
    },
  },

  beforeDestroy() {
    //console.log(this.timer)
    if (this.timer !== null) {
      //console.log("Cancelling: ",this.timer)
      clearInterval(this.timer)
    }

  },

  async beforeMount() {
    this.loadRegister();
  },
};

function drawHorizontals(page, y, width) {
  page.drawLine({
    start: { x: 10, y: y - 7 },
    end: { x: width / 2 - 5, y: y - 7 },
    thickness: 1,
    color: rgb(0.75, 0.2, 0.2),
    opacity: 0.75,
  });
  page.drawLine({
    start: { x: width / 2 + 5, y: y - 7 },
    end: { x: width - 10, y: y - 7 },
    thickness: 1,
    color: rgb(0.75, 0.2, 0.2),
    opacity: 0.75,
  });
}

function writeStudent(page, font, student, left, rowY, am, pm) {
  let name = student.PupilLastName + ", " + student.PupilFirstName;
  let location;
  if (student.location) {
    location = student.location.staffName + ", " + student.location.roomCode + ", " + student.location.groupName;
  }
  let nsize = 18;

  while (font.widthOfTextAtSize(name, nsize) > 210) {
    nsize = nsize * 0.95;
  }

  page.drawText(name, {
    x: left,
    y: rowY + 8,
    size: nsize,
    font: font,
    color: rgb(0, 0, 0),
  });

  if (location) {
    page.drawText(location, {
      x: left,
      y: rowY - 3,
      size: 8,
      font: font,
      color: rgb(0, 0, 0),
    });
  }

  let xam = am;

  if (student.am.length > 1) {
    xam = xam - 5;
  }

  page.drawText(student.am, {
    x: xam,
    y: rowY,
    size: 20,
    font: font,
    color: rgb(0, 0, 0),
  });

  let xpm = pm;

  if (student.pm.length > 1) {
    xpm = xpm - 5;
  }

  page.drawText(student.pm, {
    x: xpm,
    y: rowY,
    size: 20,
    font: font,
    color: rgb(0, 0, 0),
  });
}

function drawVerticals(page, top, width, bottom, am, pm) {
  const xs = [
    10,
    width / 2 - 5,
    am - 5,
    pm - 7.5,
    width / 2 + 5,
    width - 42.5,
    width - 75,
    width - 10,
  ];

  //console.log(xs)
  for (let x of xs) {
    page.drawLine({
      start: { x: x, y: top },
      end: { x: x, y: bottom },
      thickness: 1,
      color: rgb(0.75, 0.2, 0.2),
      opacity: 0.75,
    });
  }
}
</script>

<style>
span.v-timeago {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  list-style-image: inherit;
  list-style-position: inherit;
  list-style-type: inherit;
  letter-spacing: inherit;
}
</style>
